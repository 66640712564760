const initialState = {
  courseList: [],
  params: {
    page: 1,
    perPage: 10,
    q: "",
    status: null,
    courseId: null,
    universityId: null,
  },
  data: [],
  total: 0,
  courseOptionSelect: [],
  universityOptionSelect: [],
};
const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        params: action.params,
        data: action.data.data,
        courseList: action.data.courseList,
        total: action.data.total,
        courseOptionSelect: action.data.courseOptionSelect,
        universityOptionSelect: action.data.universityOptionSelect,
      };
    default:
      return state;
  }
};

export default courseReducer;
