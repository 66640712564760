import statusPayment from "../../../../data/statusPayment.json";

const initialState = {
  paymentCourses: [],
  paramsMain: { page: 1, perPage: 9, q: "", status: null },
  params: { page: 1, perPage: 10, q: "", status: null },
  totalCourses: 0,
  courseDetail: {},
  transactionCourseUser: {},
  transactionPaymentProofs: [],
  courseUserRegisterBean: {},
  transactionCourseUserAddress: {},
  allData: [],
  data: [],
  total: 1,
  selectedUser: null,
  courseDropdown: [],
  inputStatusDefault: [{ value: null, label: "กรุณาเลือก" }],
  historySummaries: [],
};

function getStatusDefault(value) {
  let inputStatus = [{ value: null, label: "กรุณาเลือก" }];
  statusPayment.map((item) => {
    if (value === item.value) {
      inputStatus = [item];
    }
  });
  return inputStatus;
}

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR":
      return {
        ...state,
        courseUserRegisterBean: {},
        transactionPaymentProofs: [],
        transactionCourseUserAddress: {},
        inputStatusDefault: [{ value: null, label: "กรุณาเลือก" }],
      };
    case "GET_ADMIN_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        courseDetail: action.courseDetail,
        transactionCourseUser: {},
        transactionPaymentProofs: [],
        courseUserRegisterBean: {},
      };
    case "GET_ADMIN_PAYMENT_COURSES":
      return {
        ...state,
        paramsMain: action.params,
        paymentCourses: action.data.courses,
        totalCourses: action.data.totalCourses,
        courseDetail: {},
        transactionCourseUser: {},
        transactionPaymentProofs: [],
        courseUserRegisterBean: {},
      };
    case "GET_ADMIN_PAYMENT_COURSE_DETAIL":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionPaymentProofs: action.data.transactionPaymentProofs,
        courseUserRegisterBean: action.data.courseUserRegisterBean,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
        inputStatusDefault: getStatusDefault(
            (action.data.courseUserRegisterBean ? action.data.courseUserRegisterBean.status : null)
        ),
      };
    case "GET_MOVE_COURSE_DETAIL":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionPaymentProofs: action.data.transactionPaymentProofs,
        courseUserRegisterBean: action.data.courseUserRegisterBean,
        courseDropdown: action.data.courseDropdown,
      };
    case "SAVE_ADMIN_PAYMENT_COURSE_REGISTRATION":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        courseUserRegisterBean: action.data.courseUserRegisterBean,
      };
    case "SAVE_ADMIN_PAYMENT_TRANSACTION_PAYMENT_PROOF":
      return {
        ...state,
        transactionPaymentProofs: action.data.transactionPaymentProofs,
      };
    case "SAVE_ADMIN_COURSE_STATUS":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
      };
    case "GET_HISTORY_DATA_BY_USER":
      return {
        ...state,
        historySummaries: action.data.data,
      };
    default:
      return state;
  }
};

export default courseReducer;
