const initialState = {
  courseList: [],
  params: {
    page: 1,
    perPage: 10,
    year: null,
    userId: null,
    courseId: null,
    universityId: null,
    statusRegister: null,
    trainingResults: null,
    evaluator: null,
    personalStatus: null,
  },
  data: [],
  total: 0,
  courseOptionSelect: [],
  universityOptionSelect: [],
  userOptionSelect: [],
  yearOptionSelect: [],
  statusRegisterOptionSelect: [],
  trainingResultsOptionSelect: [],
  evaluatorOptionSelect: [],
  personalStatusOptionSelect: [],
};
const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HISTORY_DATA":
      return {
        ...state,
        params: action.params,
        data: action.data.data,
        courseList: action.data.courseList,
        total: action.data.total,
        courseOptionSelect: action.data.courseOptionSelect,
        universityOptionSelect: action.data.universityOptionSelect,
        userOptionSelect: action.data.userOptionSelect,
        yearOptionSelect: action.data.yearOptionSelect,
        statusRegisterOptionSelect: action.data.statusRegisterOptionSelect,
        trainingResultsOptionSelect: action.data.trainingResultsOptionSelect,
        evaluatorOptionSelect: action.data.evaluatorOptionSelect,
        personalStatusOptionSelect: action.data.personalStatusOptionSelect,
      };
    default:
      return state;
  }
};

export default courseReducer;
