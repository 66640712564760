const initialState = {
  paymentCourses: [],
  params: {},
  totalCourses: 0,
  courseDetail: {},
  transactionCourseUser: {},
  transactionPaymentProofs: [],
  configMasterCode10001: {},
  configMasterCode10002: {},
  transactionCourseUserAddress: {},
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PAYMENT_COURSES":
      return {
        ...state,
        paymentCourses: action.data.courses,
        params: action.params,
        totalCourses: action.data.totalCourses,
      };
    case "GET_PAYMENT_COURSE_DETAIL":
      return {
        ...state,
        configMasterCode10001: action.data.configMasterCode10001,
        configMasterCode10002: action.data.configMasterCode10002,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionPaymentProofs: action.data.transactionPaymentProofs,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
      };
    case "SAVE_PAYMENT_COURSE_REGISTRATION":
      return {
        ...state,
        //courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
      };
    case "SAVE_PAYMENT_TRANSACTION_PAYMENT_PROOF":
      return {
        ...state,
        transactionPaymentProofs: action.data.transactionPaymentProofs,
      };
    case "SAVE_RECEIPT":
      return {
        ...state,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
      };
    default:
      return state;
  }
};

export default courseReducer;
