const initialState = {
  courses: [],
  params: {},
  totalCourses: 0,
  courseDetail: {},
  transactionCourseUser: {},
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MY_COURSES":
      return {
        ...state,
        courses: action.data.courses,
        params: action.params,
        totalCourses: action.data.totalCourses,
      };
    case "GET_MY_COURSE_DETAIL":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
      };
    case "SAVE_MY_COURSE_REGISTRATION":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
      };
    default:
      return state;
  }
};

export default courseReducer;
