const initialState = {
  member: {},
  members: [],
  memberUniversity: [],
  universityOptionSelect: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MEMBER_GET_MEMBER":
      return {
        ...state,
        members: action.data.members,
      };
    case "MEMBER_SAVE_MEMBER":
      return {
        ...state,
        members: action.data.members,
      };
    case "MEMBER_GET_MEMBER_UNIVERSITY":
      return {
        ...state,
        memberUniversities: action.data.memberUniversities,
        universityOptionSelect: action.data.universityOptionSelect,
      };
    case "MEMBER_SAVE_MEMBER_UNIVERSITY":
      return {
        ...state,
        memberUniversities: action.data.memberUniversities,
        universityOptionSelect: action.data.universityOptionSelect,
      };
    case "MEMBER_SET_PARAM":
      return {
        ...state,
        member: action.member,
      };
    default:
      return state;
  }
};

export default reducer;
