// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import course from "@src/views/pages/course/reducer";
import payment from "@src/views/pages/payment/reducer";
import myCourse from "@src/views/pages/mycourse/reducer";
import adminPayment from "@src/views/pages/admin-payment/reducer";
import adminUserAll from "@src/views/pages/user/reducer";
import dashboard from "@src/views/pages/home/reducer";
import publicPage from "@src/views/pages/public/reducer";
import history from "@src/views/pages/history/reducer";
import member from "@src/views/pages/member/reducer";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  course,
  payment,
  myCourse,
  adminPayment,
  adminUserAll,
  dashboard,
  publicPage,
  history,
  member,
});

export default rootReducer;
