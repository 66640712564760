const initialState = {
  courses: [],
  params: {},
  totalCourses: 0,
  courseDetail: {},
  transactionCourseUser: {},
  transactionCourseUserAddress: {},
};

const courseReducer = (state = initialState, action) => {
  if (action.data && action.data.statusDesc === "ไม่สามารถเข้าถึงข้อมูลได้") {
    window.location = window.location.origin.concat("/course");
  }
  switch (action.type) {
    case "GET_COURSES":
      return {
        ...state,
        courses: (action.data.courses ? action.data.courses : state.courses),
        params: {...state.params, ...action.params},
        totalCourses: (action.data.totalCourses ? action.data.totalCourses : state.totalCourses),
      };
    case "GET_COURSE_DETAIL":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
      };
    case "SAVE_COURSE_REGISTRATION":
      return {
        ...state,
        courseDetail: action.data.courseDetail,
        transactionCourseUser: action.data.transactionCourseUser,
        transactionCourseUserAddress: action.data.transactionCourseUserAddress,
      };
    default:
      return state;
  }
};

export default courseReducer;
